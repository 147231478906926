<template>
  <div>
    <video-player
      class="kf-video"
      ref="play"
      v-if="src && src.includes('.m3u8')"
      @timeupdate="timeupdate"
      @pause="pause"
      @play="play"
      @ready="onLoadStart"
      :options="{
        sources: [{ type: 'application/x-mpegurl', src: this.src }],
        autoplay: this.autoplay,
        poster: this.poster,
        muted: this.muted,
        controls: this.controls,
        preload: this.preload,
        fluid: this.fluid,
        loop: this.loop,
      }"
      :playsinline="true"
    />
    <video
      v-if="src && src.indexOf('.m3u8') == -1"
      ref="kfVideo"
      :src="src"
      class="PlayV-video"
      @loadstart="onLoadStart"
      width="880px"
      height="500px"
      @timeupdate="timeupdate"
      @pause="pause"
      @play="play"
      :go-time="goTime"
      preload
      controls
      playsinline
    ></video>
  </div>
</template>

<script>
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player/src";
import "videojs-contrib-hls";

export default {
  data() {
    return {};
  },
  props: {
    // 视频地址
    src: {
      type: String,
      required: true,
    },
    // 封面
    poster: String,
    // 静音
    muted: Boolean,
    // 自动播放
    autoplay: Boolean,
    // 操作栏
    controls: Boolean,
    // 自动加载
    preload: Boolean,
    // 尺寸自适应
    fluid: Boolean,
    // 循环
    loop: Boolean,
    // 进度
    goTime: Number,
  },
  components: { videoPlayer },
  mounted() {},
  methods: {
    onLoadStart(e) {
      if (this.src.indexOf(".m3u8") > -1) {
        if (this.goTime) {
          e.currentTime(this.goTime);
        }
      } else {
        if (this.goTime) this.$refs["kfVideo"].currentTime = this.goTime;
      }
    },
    timeupdate(e) {
      if (this.src.indexOf(".m3u8") > -1) {
        this.$emit("timeupdate", e.cache_.currentTime);
      } else {
        this.$emit("timeupdate", e.target.currentTime);
      }
    },
    pause() {
      this.$emit("pause");
    },
    play() {
      this.$emit("play");
    },
  },
};
</script>
<style lang="less"></style>
