<template>
  <div class="back">
    <div class="g-box">
      <div class="main fl-sb">
        <div class="main-le">
          <div class="title">{{ konwledgeInfo.name }}</div>
          <div class="video">
            <kf-video
              :fluid="true"
              :controls="true"
              :preload="true"
              @pause="pause"
              @play="play"
              @timeupdate="timeupdate"
              :goTime="konwledgeInfo.progress ? konwledgeInfo.progress : 0"
              :src="konwledgeInfo.videoUrl"
            ></kf-video>
          </div>
        </div>
        <div class="main-ri">
          <div class="title fl-ac">
            <div class="line"></div>
            <div class="txt">课程目录({{ total }})</div>
          </div>
          <div class="content">
            <div
              class="item fl-ac"
              :class="item.id == konwledgeInfo.id ? 'active' : ''"
              v-for="(item, i) in konwledgeList"
              :key="item.id"
              @click="changeItem(i)"
            >
              <div style="display: flex">
                <div class="icon">
                  <img
                    class="img"
                    v-if="konwledgeInfo.id === item.id"
                    src="../../assets/img/icon/icon_stop.png"
                    alt=""
                  />
                  <img
                    v-else
                    class="img"
                    src="../../assets/img/icon/icon_go.png"
                    alt=""
                  />
                </div>
                <div class="txt over-hd">
                  {{ item.name }}
                </div>
              </div>

              <div>
                <span
                  >{{
                    ((item.progress / item.duration) * 100).toFixed(2)
                  }}%</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import page_mx from "@/mixin/page_mx";
import kfVideo from "../../components/kf-video.vue";
export default {
  mixins: [page_mx],
  components: { kfVideo },
  data() {
    return {
      query: this.$route.query,
      konwledgeList: [],
      activeIndex: 0,
      konwledgeInfo: {},
      lookTime: 0,
      total: 0,
      saveVideoTimer: "",
    };
  },
  methods: {
    init() {
      if (this.query.courseId) {
        this.$api.cms.resource.knowledge_listAdultEducationKnowledgeForClient
          .get({
            courseId: this.query.courseId,
          })
          .then((res) => {
            this.konwledgeList = res.data;
            this.total = res.data.length;
            if (this.query.lastKnowledgeId > 0) {
              this.konwledgeInfo = res.data.find((item) => {
                return item.id == this.query.lastKnowledgeId;
              });
              console.log(this.konwledgeInfo);
            } else {
              this.activeIndex = 0;
              this.konwledgeInfo = res.data[0];
            }
          });
      }
    },
    async changeItem(i) {
      if (this.lookTime > 60) {
        await this.$api.sms.study.studentKnowledge_progress.put({
          knowledgeId: this.konwledgeInfo.id,
          progress: this.lookTime,
          study: 0,
          type: "录播课",
        });
        const {
          data: konwledgeList,
        } = await this.$api.cms.resource.knowledge_listAdultEducationKnowledgeForClient.get(
          {
            courseId: this.query.courseId,
          }
        );
        this.konwledgeList = konwledgeList;
      }
      this.konwledgeInfo = this.konwledgeList[i];
    },
    timeupdate(time) {
      this.lookTime = parseInt(time);
    },
    changeStudy() {
      this.$api.sms.study.studentKnowledge_progress.put({
        knowledgeId: this.konwledgeInfo.id,
        progress: this.lookTime,
        study: 1,
        type: "录播课",
      });
    },
    play() {
      clearInterval(this.saveVideoTimer);
      this.saveVideoTimer = setInterval(() => {
        console.log("一分钟记录一下");
        this.changeStudy();
      }, 1 * 60 * 1000);
    },
    pause() {
      clearInterval(this.saveVideoTimer);
    },
  },

  async beforeDestroy() {
    clearInterval(this.saveVideoTimer);
    if (this.lookTime > 60) {
      await this.$api.sms.study.studentKnowledge_progress.put({
        knowledgeId: this.konwledgeInfo.id,
        progress: this.lookTime,
        study: 0,
        type: "录播课",
      });
    }
  },
};
</script>
<style lang="less" scoped>
.back {
  display: flex;
  align-items: center;
  background-color: #f3f4f9;
  margin-top: 61px;
  margin-bottom: 41px;
}
.main {
  // height: 500px;
  &-le {
    .title {
      padding-left: 20px;
      background-color: #fff;
      display: flex;
      align-items: center;
      height: 60px;
      font-size: 16px;
      font-weight: 600;
      color: #2d3134;
    }
    .video {
      width: 880px;
      height: 500px;
      background-color: rgb(238, 237, 237);
    }
  }
  &-ri {
    background-color: #fff;
    .content {
      height: 500px;
      overflow-y: auto;
    }
    // .content::-webkit-scrollbar {
    //   width: 0 !important;
    // }
    .title {
      height: 60px;
      .line {
        margin-right: 10px;
        margin-left: 20px;
        width: 4px;
        height: 16px;
        background: linear-gradient(135deg, #19b6ff 0%, #1886ff 100%);
        border-radius: 3px;
      }
      .txt {
        font-size: 16px;
        font-weight: 600;
        color: #2d3134;
      }
    }
    .item {
      padding-left: 20px;
      box-sizing: border-box;
      height: 60px;
      border-top: 1px solid #e6ebf0;
      cursor: pointer;
      .icon {
        width: 20px;
        height: 20px;
        .img {
          width: 100%;
          height: 100%;
        }
      }
      .txt {
        padding-left: 10px;
        width: 200px;
        font-size: 14px;
        color: #2d3134;
      }
    }
    .item.active {
      .txt {
        color: #1886ff;
      }
    }
  }
}
</style>
