var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.src && _vm.src.includes('.m3u8'))?_c('video-player',{ref:"play",staticClass:"kf-video",attrs:{"options":{
      sources: [{ type: 'application/x-mpegurl', src: this.src }],
      autoplay: this.autoplay,
      poster: this.poster,
      muted: this.muted,
      controls: this.controls,
      preload: this.preload,
      fluid: this.fluid,
      loop: this.loop,
    },"playsinline":true},on:{"timeupdate":_vm.timeupdate,"pause":_vm.pause,"play":_vm.play,"ready":_vm.onLoadStart}}):_vm._e(),(_vm.src && _vm.src.indexOf('.m3u8') == -1)?_c('video',{ref:"kfVideo",staticClass:"PlayV-video",attrs:{"src":_vm.src,"width":"880px","height":"500px","go-time":_vm.goTime,"preload":"","controls":"","playsinline":""},on:{"loadstart":_vm.onLoadStart,"timeupdate":_vm.timeupdate,"pause":_vm.pause,"play":_vm.play}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }